<template>
    <div class="incubator-final-upload">
        <v-form
            ref="form"
            v-model="valid"
        >
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorFinal.upload.title[0]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-file-input
                        v-model="pdfFile"
                        accept=".pdf"
                        :label="$t('IncubatorFinal.upload.label')"
                        outlined
                        placeholder=""
                        :hint="$t('IncubatorFinal.upload.hint')"
                        persistent-hint
                        :rules="[pdfRule()]"
                        class="border-orange-input form-input"
                    />
                    <div
                        v-if="tmpPdf"
                        class="preivew"
                    >
                        <a
                            :href="$static(tmpPdf)"
                            target="_blank"
                            class="preview-link"
                        >{{ $t('IncubatorFinal.upload.link') }}</a>
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('IncubatorFinal.upload.title[1]') }}
                    </div>
                </div>
                <div class="form-content">
                    <ol class="tips">
                        <li>{{ $t('IncubatorFinal.upload.tips[0]') }}</li>
                        <li>
                            {{ $t('IncubatorFinal.upload.tips[1]') }}
                        </li>
                        <li>
                            {{ $t('IncubatorFinal.upload.tips[2]') }}
                        </li>
                        <li>{{ $t('IncubatorFinal.upload.tips[3]') }}</li>
                        <li>
                            {{ $t('IncubatorFinal.upload.tips[4]') }}
                        </li>
                    </ol>
                </div>
            </div>
        </v-form>
        <div class="apply-form-block">
            <div class="left-block" />
            <div class="form-content">
                <div class="actions">
                    <div
                        class="btn orange-btn"
                        @click="nextStep"
                    >
                        {{ $t('IncubatorFinal.upload.button') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import _ from 'lodash';
import { mapMutations } from 'vuex';
import API from '../../api';

export default {
    components: {},
    data() {
        return {
            valid: false,
            loading: false,
            pdfFile: null,
            // rules
            emptyRule: [(v) => !!v || this.$t('IncubatorFinal.upload.rule[0]')],
            pdfRule() {
                if (this.pdfFile && this.pdfFile.type !== 'application/pdf') {
                    return this.$t('IncubatorFinal.upload.rule[1]');
                }
                if (this.pdfFile) {
                    return true;
                }
                return this.$t('IncubatorFinal.upload.rule[2]');
            },
        };
    },
    computed: {
        applyId() {
            return this.$route.params.id;
        },
        tmpPdf() {
            if (this.pdfFile) {
                return URL.createObjectURL(this.pdfFile);
            }
            return '';
        },
    },
    created() {
    },
    mounted() { },
    methods: {
        ...mapMutations('mNotification', ['handleError', 'setAlert']),
        ...mapMutations('mLoading', ['setLoading']),
        async nextStep() {
            this.$refs.form.validate();
            if (this.valid) {
                try {
                    this.setLoading(true);
                    await API.IncubatorApplyUtil.FinalSubmit(
                        this.applyId, this.pdfFile,
                    );
                    this.setLoading(false);
                    this.$router.push({
                        name: 'IncubatorFinalComplete',
                        params: {
                            id: this.applyId,
                        },
                    });
                } catch (e) {
                    this.setLoading(false);
                    this.handleError({
                        message: e || this.$t('alertMessage.upload-fail'),
                        color: 'error',
                    });
                }
            } else {
                this.setAlert({
                    message: this.$t('alertMessage.choose-file'),
                    color: 'error',
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.tips {
    font-weight: 700;
    font-size: 16px;
    line-height: 2;
    letter-spacing: 0.1em;
    color: $color-gray;
}

.actions {
    justify-content: center;
}
</style>
